<template>
  <section class="">
    <div
      v-if="inpType !== 'textarea' && inpType !== 'drag-and-drop'"
      class="is-flex is-align-items-center mb-3"
    >
      <div v-if="inpTitleStart !== ''" class="mr-3 text">
        {{ inpTitleStart }}
      </div>
      <template v-if="inpType === 'select'">
        <div class="is-size-5">
          <div class="select">
            <select
              v-model="inputValue"
              :style="'width: ' + width"
              name="inpName"
              :required="inpRequired"
              :tabindex="inpTab"
              :disabled="disabled"
              @change="updateInput('change', inputValue)"
            >
              
              <template v-if="inpDataName == 'departaments'">
                <option v-for="(i, k) in data" :key="k" :value="i.name">
                  {{ i.name }}
                </option>
              </template>
              <template v-else-if="data">
                <option
                  v-for="{ option } in data"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </template>
            </select>
          </div>
        </div>
      </template>
      <template v-else-if="inpType === 'checkbox'">
        <div class="is-size-5">
          <div class="squaredOne">
            <input
              :id="'squaredOne' + inpName"
              v-model="inputValue"
              :style="'width: ' + width"
              :type="inpType"
              class="squaredOne"
              :name="inpName"
              hidden="hidden"
              :required="inpRequired"
              :disabled="disabled"
              @change="updateInput('change', inputValue)"
            />
            <label :tabindex="inpTab" :for="'squaredOne' + inpName"></label>
          </div>
        </div>
      </template>
      <template v-else-if="inpType === 'number'">
        <div class="is-size-5">
          <input
            v-model="inputValue"
            :style="'width: ' + width"
            :type="inpType"
            :name="inpName"
            :required="inpRequired"
            class="input"
            :min="inpMin"
            :max="inpMax"
            :tabindex="inpTab"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="updateInput('input', inputValue, inpName)"
          />
        </div>
      </template>
      <template v-else-if="inpType === 'email'">
        <div class="is-half">
          <input
            v-model="inputValue"
            :style="'width: ' + width"
            :type="inpType"
            :name="inpName"
            :required="true"
            class="input"
            :tabindex="inpTab"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="updateInput('input', inputValue, inpName)"
          />
        </div>
      </template>
      <template v-else>
        <div class="is-size-5">
          <input
            v-model="inputValue"
            :style="'width: ' + width"
            :type="inpType"
            :name="inpName"
            class="input"
            :tabindex="inpTab"
            :required="inpRequired"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="updateInput('input', inputValue, inpName)"
          />
        </div>
      </template>
      <div v-if="inpTitleEnd !== ''" class="text">{{ inpTitleEnd }}</div>
    </div>
    <template v-else-if="inpType === 'textarea'">
      <div class="mb-3">
        <p class="text" style="display: block">{{ inpTitleStart }}</p>
        <textarea
          v-model="inputValue"
          :placeholder="placeholder"
          :rows="8"
          :style="'width:' + width + ';'"
          @input="updateInput('input', inputValue, inpName)"
        ></textarea>
      </div>
    </template>
    <template v-else-if="inpType === 'drag-and-drop'">
      <b-field>
        <b-upload
          v-model="inputValue"
          multiple
          drag-drop
          class="custom-dd"
          @input="$emit('input', inputValue)"
        >
          <section class="section">
            <div class="content has-text-centered">
              <p>
                Arrastra los archivos a este cuadro o hacer clic para
                seleccionar
              </p>
            </div>
          </section>
        </b-upload>
      </b-field>
      <div class="tags">
        <span
          v-for="(file, index) in inputValue"
          :key="index"
          class="tag is-info"
        >
          {{ file.name }}
          <button
            class="delete is-small"
            type="button"
            @click="deleteDropFile(index)"
          ></button>
        </span>
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: "Input",
  props: [
    "inpTitleStart",
    "inpTitleEnd",
    "inpType",
    "inpDataName",
    "isRequired",
    "inpName",
    "inpChecked",
    "inpMin",
    "inpMax",
    "inpValue",
    "disabled",
    "inpTab",
    "placeholder",
    "inpRequired",
    "width",
    "data",
  ],
  data() {
    return { inputValue: "" };
  },
  created() {
    if (this.inputValue === "") {
      this.inputValue = this.inpValue;
    }
  },
  methods: {
    updateInput(type, value, name) {
      this.$emit(type, value, name);
    },
    deleteDropFile(index) {
      this.inputValue.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.custom-dd {
  display: block;
  margin-bottom: 30px;
  width: 100%;
}
.custom-dd p {
  margin: 0;
  color: #bdbdbd;
}
.input-ul {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  margin-bottom: 10px;
}
.input-ul li {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}
.input-ul li:last-child {
  margin-right: 0;
}

input {
  background-color: transparent;
  /* width: initial!important; */
}
textarea:focus-visible,
select:focus-visible,
input:focus-visible {
  outline: none;
}
textarea,
select,
input[type="text"],
input[type="email"],
input[type="number"] {
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 3px solid #fbaf33 !important;
}

.input-ul li:first-child,
.input-ul li:last-child {
  font-size: 24px;
  color: #00758f;
}

/* SQUARED ONE */
.squaredOne {
  width: 28px;
  height: 28px;
  background: #fcfff4;
  margin: 20px auto;
  position: relative;
}

.squaredOne label {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  border: 3px solid #fbaf33;
}

.squaredOne label:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fbaf33;
  top: 3px;
  left: 2px;
}

.squaredOne label:hover::after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  opacity: 0.3;
}

.squaredOne input[type="checkbox"]:checked + label:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
}

.text {
  font-weight: normal;
  font-size: 24px;
  color: #00758f;
  line-height: 1.9rem;
}
@media screen and (max-width: 42em) {
  .text {
    font-size: 20px;
    font-weight: normal;
  }
  .is-flex {
    display: block !important;
    text-align: left;
  }
  input[type="number"],
  input[type="email"],
  input[type="text"] {
    width: 200px !important;
  }
  select {
    width: 190px !important;
  }
}
</style>
