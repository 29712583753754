<template>
  <section class="linkthree-container">
    <div class="container-fluid">
      <div class="columns">
        <div class="column">
          <h2 class="title title-icons">
            <span>{{ title }}</span>
          </h2>
        </div>
      </div>
      <!--  -->
      <b-carousel-list
        :data="items"
        :items-to-show="itemsToShow"
        :overlay="false"
        :autoplay="true"
        :interval="3000"
      >
        <template #item="i">
          <article class="circle">
            <a :href="i.link">
              <strong>{{ i.title }}</strong>
              <template v-if="i.text">
                {{ i.text }}
              </template>
            </a>
          </article>
        </template>
      </b-carousel-list>
    </div>
  </section>
</template>

<script>
export default {
  name: "LinkThree",
  props: ["title"],
  data() {
    return {
      itemsToShow: 6,
      items: [
        {
          link: "https://afiliaciononline.osfatun.com.ar/",
          title: "Quiero afiliarme",
          text: "",
        },
        {
          link: "http://mi.osfatun.com.ar/",
          title: "Mi osfatun",
          text: "-Tramites Online-",
        },
        {
          link: "https://prestadores.osfatun.com.ar/",
          title: "Cartilla de prestadores",
          text: "",
        },
        {
          link: "https://www.osfatun.com.ar/planes/",
          title: "Planes",
          text: "",
        },
        {
          link: "https://www.osfatun.com.ar/delegaciones/",
          title: "Delegaciones",
          text: "",
        },
      ],
    };
  },
  created() {
    if (this.isMobile()) {
      this.itemsToShow = 2;
    } else {
      this.itemsToShow = 6;
    }
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.carousel-list {
  box-shadow: none !important;
}
.linkthree-container {
  margin: 0 auto;
  max-width: 83%;
  padding: 3%;
}

h2.title.title-icons {
  font-weight: bold;
  font-size: 36px;
  text-transform: uppercase;
  color: #00758f;
  width: 100%;
  text-align: center;
  padding: 40px 0;
}
/* ul.icons {
  display: grid;
  grid-gap: 5px;
  justify-content: space-evenly;
  width: 100%;
}
ul.icons li {
  color: #000;
  font-weight: lighter;
  display: grid;
  float: left;
  font-size: 20px;
  align-content: center;
  justify-content: center;
  line-height: 1rem;
  position: relative;
  text-align: center;
} */
.circle {
  background: linear-gradient(
    54deg,
    rgba(0, 147, 191, 1) 0%,
    rgba(0, 117, 143, 1) 100%
  );
  color: #fff;
  border-radius: 100%;
  vertical-align: middle;
  border: 0;
  position: relative;
  width: 100%;
  height: auto;
}
.circle a {
  color: #fff;
  font-size: 16px;
  line-height: 1.3rem;
  display: block;
  text-align: center;
  width: 100%;
  height: 185px;
  padding-top: 40%;
}
.circle a strong {
  text-transform: uppercase;
}

.circle {
  background: #0093bf;
  background: linear-gradient(54deg, #0093bf 0%, #00758f 100%);
  color: #fff;
  display: flexbox;
  padding: 10px;
  border-radius: 100%;
  vertical-align: middle;
  width: 185px;
  height: 185px;
  border: 0;
  position: relative;
  border: 3px solid #0093bf;
}
.circle:hover {
  border: 3px solid #f5a22d;
}
.circle strong {
  display: block;
}
.headerDegraded {
  background: rgb(0, 147, 191);
  background: linear-gradient(
    54deg,
    rgba(0, 147, 191, 1) 0%,
    rgba(0, 117, 143, 1) 100%
  );
}
@media screen and (max-width: 42em) {
  .front-container {
    max-width: 90%;
  }
  h2.title.title-icons {
    padding: 20px 0;
    margin-top: 30px;
  }
  h2.title.title-icons strong {
    display: block;
    font-size: 56px;
  }
  h2.title.title-icons span {
    font-size: 20px;
  }
  .circle {
    width: 150px;
    height: 150px;
    padding: 5px;
  }
  ul.icons {
    overflow: scroll;
    grid-template-columns: auto auto;
  }
}
</style>
