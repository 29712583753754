<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  metaInfo: {
    title: "OSFATUN Sorteos",
    titleTemplate: null,
    htmlAttrs: {
      lang: "es-ES",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "OSFATUN Landing Sorteos",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>
<style>
@import "~bulma/css/bulma.css";
@import "assets/GothamRounded/main-loader.css";
html{
  scroll-behavior: smooth;
  background-color: #F4F4F4;
}
#app {
  font-family: "Gotham Rounded", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
