<template>
  <section class="red-socials">
    <ul class="icons soloMobile">
      <li v-for="(i, k) in social" :key="k">
        <a :href="i.url" target="_blank">
          <img class="icon" :width="i.width" :src="i.image" :alt="i.title" />
        </a>
      </li>
    </ul>
    <ul class="icons soloMobile mt-5">
      <li><h2 class="title">Seguinos</h2></li>
      <li class="second">
        <img src="./../../assets/social/en-las-redes.svg" alt="" />
      </li>
    </ul>

    <ul class="icons soloDesktop">
      <li><h2 class="title">Seguinos</h2></li>
      <li class="second">
        <img src="./../../assets/social/en-las-redes.svg" alt="" />
      </li>
      <li v-for="(i, k) in social" :key="k">
        <a :href="i.url" target="_blank">
          <img
            style="width: 32px; height: 32px"
            :src="i.image"
            :alt="i.title"
          />
        </a>
      </li>
    </ul>
  </section>
</template>

<script>
import imgFacebook from "./../../assets/social/facebook.svg";
import imgInstagram from "./../../assets/social/instagram.svg";
// import imgTwitter from './../../assets/social/twitter.svg'
// import imgLinkedin from './../../assets/social/linkedin.svg'
export default {
  name: "RedSocials",
  props: ["type"],
  data() {
    return {
      social: [
        {
          url: "https://www.facebook.com/osfatunoficial",
          title: "Facebook",
          image: imgFacebook,
        },
        {
          url: "https://www.instagram.com/osfatunoficial",
          title: "Instagram",
          image: imgInstagram,
        },
      ],
    };
  },
};
</script>

<style scoped>
.icons {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto auto auto auto auto;
  justify-content: center;
  width: 100%;
}
.icons li {
  color: #000;
  font-weight: lighter;
  display: grid;
  float: left;
  font-size: 20px;
  align-content: center;
  justify-content: center;
  line-height: 1rem;
  position: relative;
  text-align: center;
  border: 0;
  vertical-align: text-bottom;
}
.icons li a {
  color: #fff;
  font-size: 16px;
  line-height: 1.3rem;
}
ul.icons li h2.title {
  margin: 0;
  padding: 0;
}
ul.icons li.second {
  padding-bottom: 15px;
}

.soloMobile {
  display: none;
}
h2.title {
  color: #00758f;
}

@media screen and (max-width: 42em) {
  .title-web {
    display: none;
  }
  .soloDesktop {
    display: none;
  }
  .soloMobile {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto auto;
  }
  .svg-background {
    margin-bottom: 40px;
  }
  .instagramfeed-container {
    overflow: hidden;
    margin-top: 30px;
  }
  .red-socials {
    max-width: 83%;
    margin: 0 auto;
    margin-top: 30px;
  }
}
</style>
