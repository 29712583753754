<template>
  <header class="header">
    <nav>
      <div class="columns is-align-items-center is-multiline is-mobile pt-2">
        <div
          class="column pb-1 is-12-mobile is-6-desktop has-text-left-desktop"
        >
          <a href="https://www.osfatun.com.ar/">
            <img
              src="./../../assets/logo/osfatun-3.svg"
              width="200px"
              alt="osfatun"
              class="logo"
            />
          </a>
        </div>
        <div
          class="column pt-0 is-12-mobile is-6-desktop has-text-right-desktop"
        >
          <ul class="menu">
            <li v-for="(i, k) in menuList" :key="k">
              <a :href="i.url">{{ i.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      menuList: [
        {
          url: "https://www.osfatun.com.ar/planes/",
          name: "VER PLANES",
        },
        { url: "https://www.osfatun.com.ar/", name: "| IR AL SITIO WEB" },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped>
.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  max-width: 83%;
  margin: 0 auto;
  margin-top: 10px;
}
.menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu li {
  margin-right: 10px;
  display: inline-block;
}
.menu li a {
  color: #fff;
  font-size: 16px;
  line-height: 1.3rem;
}
.menu li:last-child {
  margin-right: 0;
}
</style>
