<template>
  <section class="mt-2 mb-2">
    <div id="instafeed" class="columns is-gapless is-mobile is-multiline"></div>
  </section>
</template>

<script>
export default {
  name: "InstagramFeed",
};
</script>

<style scoped>
img {
  display: block;
  margin: 0 auto;
}
</style>
