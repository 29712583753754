<template>
  <footer>
    <LinkThree :title="'Conocé OSFATUN'" />
    <section class="svg-background"></section>
    <RedSocials :type="'footer'" />
    <InstagramCarousel />
    <section class="footer-container">
      <section class="container">
        <div class="columns is-align-items-center is-multiline is-mobile">
          <div class="column is-12-mobile is-4-desktop has-text-left">
            <a href="https://www.osfatun.com.ar/">
              <img width="216px" src="./../../assets/logo/osfatun-2.svg" />
            </a>
            <FiscalCode />
          </div>
          <div class="column is-12-mobile is-2-desktop has-text-centered">
            <ul class="list-footer">
              <li v-for="(i, k) in menuListOne" :key="k">
                <a :href="i.link">{{ i.title }}</a>
              </li>
            </ul>
          </div>
          <div class="column is-12-mobile is-3-desktop has-text-centered">
            <ul class="list-footer">
              <li v-for="(i, k) in menuListTwo" :key="k">
                <a :href="i.link">{{ i.title }}</a>
              </li>
            </ul>
          </div>
          <div class="column is-12-mobile is-3-desktop has-text-centered">
            <SmallNewsletter />
            <a target="_blank" href="https://www.argentina.gob.ar/sssalud">
              <img
                src="./../../assets/logo/sssaludconinfo.svg"
                width="336px"
                alt="zaro"
              />
            </a>
          </div>
        </div>
        <div class="columns zaro is-multiline is-mobile">
          <div class="column is-12-mobile is-6-desktop has-text-left">
            <a target="_blank" href="https://www.fatun.org.ar/"
              ><img
                class="logo-federacion"
                src="./../../assets/logo/federacion.svg"
            /></a>
          </div>
          <div class="column is-12-mobile is-6-desktop has-text-right">
            <a target="_blank" href="https://zaro.com.ar"
              ><img
                class="zaro"
                src="./../../assets/logo/zaro.svg"
                width="85"
                alt="zaro"
            /></a>
          </div>
        </div>
      </section>
    </section>
  </footer>
</template>

<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-15197811-4"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-15197811-4');
</script>



<script>
import RedSocials from "./RedSocials";
import InstagramCarousel from "./instagram/InstagramCarousel";
import SmallNewsletter from "./SmallNewsletter";
import FiscalCode from "./FiscalCode";
import LinkThree from "./LinkThree.vue";
export default {
  name: "Footer",
  components: {
    InstagramCarousel,
    RedSocials,
    SmallNewsletter,
    FiscalCode,
    LinkThree,
  },
  data() {
    return {
      menuListOne: [
        // {
        //   link: "https://d199yrd2gy0u5k.cloudfront.net/",
        //   title: "Cotizador online",
        //   target: "_blank",
        // },
        { link: "https://www.osfatun.com.ar/nosotros/", title: "Nosotros" },
        {
          link: "https://www.osfatun.com.ar/delegaciones/",
          title: "Delegaciones",
        },
        {
          link: "https://www.osfatun.com.ar/planes-y-servicios/",
          title: "Planes y servicios",
        },
        {
          link: "https://www.osfatun.com.ar/beneficiarios/",
          title: "Beneficiarios",
        },
      ],
      menuListTwo: [
        {
          link: "https://www.osfatun.com.ar/prestadores/",
          title: "Cartilla de prestadores",
          target: "_blank",
        },
        // { link: baseUrl + "/blog", title: "Blog y Novedades" },
        {
          link: "http://www.fiscalizacion.com.ar/",
          title: "Fiscalizacion",
          target: "_blank",
        },
        { link: "https://www.osfatun.com.ar/contacto/", title: "Contacto" },
        // { link: baseUrl + "/arrepentimiento", title: "Form. Arrepentimiento" },
      ],
    };
  },
};
</script>

<style scoped>
.footer-container {
  margin-top: 50px;
  padding: 20px 0;
}
.svg-background {
  width: 100%;
  background: url("./../../assets/forBackground/nuestro-plan-es-cuidarte.svg")
    center center no-repeat;
  background-size: 100%;
  height: 253px;
  opacity: 0.6;
}
.zaro {
  margin-top: 10px;
}
.list-footer li {
  text-align: left;
}
.list-footer li a {
  color: #00758f;
  font-size: 18px;
  font-weight: bold;
}
@media screen and (max-width: 42em) {
  .footer-container {
    margin-top: 0;
    overflow: hidden;
  }
  .zaro {
    margin-top: 10px;
  }
  .logo-federacion {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  .svg-background {
    display: none;
  }
  .has-text-left,
  .has-text-right {
    text-align: center !important;
  }
  .list-footer li {
    text-align: center;
  }
}
</style>
